
import React from "react";
import Joueur from "./joueur";
import * as Constants from "./constants"
import Bouton from './boutons'
class JoueurList extends React.Component {

    constructor(props) {
        super(props);
        this.ajouterJoueur = this.ajouterJoueur.bind(this)
        this.jouer = this.jouer.bind(this)
        
        this.state = {
            inputValue: '',
            joueurs : [],
            uiItems: [],
            count:0,
            joueurPresent: false,
            nomVide:false,
            mounted:false
          };
    }
  
    componentDidMount() {
        this.setState({mounted:true})
    }

    ajouterJoueur() {
        if (this.state.inputValue !== "" && !this.state.joueurs.some(name => (name === this.state.inputValue))) {
            var id = this.state.count + 1
            this.setState({
                joueurs: this.state.joueurs.concat(this.state.inputValue),            
                uiItems: this.state.uiItems.concat(
                    <Joueur key={id}  nom={this.state.inputValue} parent={this} id={id}></Joueur>
                ),
                count: this.state.count + 1,

            })
            this.setState({inputValue:""})
        } else if (this.state.inputValue === "") {
            this.setState({nomVide:true})
        }
    }

    checkKeyPressed(evt) {
        if (evt.key === "Enter") {
            this.ajouterJoueur()
        }
    }

    supprJoueur(e) {
            this.setState({uiItems: this.state.uiItems.filter(function(joueur) {
                return joueur.key.toString() !== e.state.id.toString()
            })});   
            this.setState({joueurs: this.state.joueurs.filter(function(name) {
                return name !== e.state.nom
            })})
    }


    jouer() {
        if (this.state.mounted) {
            console.log(this.state.joueurs);
            this.props.choixMode(this.state.joueurs)
        }
        
    }

    render() {
      return (

            <div className="bg-white bg-opacity-35 rounded-2xl h-[80%] p-6 m-4 w-4/5 sm:w-full lg:w-3/4 lg:max-w-lg shadow-2xl shadow-slate-900">
                <div className="h-[15%]">
                    <label className=" text-white text-2xl  font-medium dark:text-white" htmlFor="nomJoueur">Entrez le nom d'un joueur: </label>
                    <div className="mt-4 flex">
                        <input className={(this.state.joueurPresent && Constants.inputError) || Constants.inputNormal + " w-5/6"} id="nomJoueur" value={this.state.inputValue} onChange={evt => this.updateInputValue(evt)} onKeyDown={evt => this.checkKeyPressed(evt)}/>
                        <button className={Constants.btnAddNormal + "w-1/6"} id="btnAjouterJoueur" onClick={(e) => this.ajouterJoueur(e)}>
                            <Bouton type="add"></Bouton>
                        </button>
                    </div>
                </div>
                <div className="h-[70%] overflow-auto pt-8 mb-4">
                    {this.state.uiItems}
                </div>
                <div className="h-[15%]">
                    <button className={Constants.btn} onClick={() => {this.jouer()}}>Jouer</button>
                    {this.state.joueurPresent && <p className={Constants.msgError}>Attention! Ce joueur est déjà présent dans la liste...</p> }
                    {this.state.nomVide && <p className={Constants.msgError}>"Attention! Le nom d'un ne peut pas être vide..." </p>}
                </div>

            </div>
      )
    }

    updateInputValue(evt) {
        this.setState({inputValue: evt.target.value});
        if (this.state.joueurs.some(name => (name.toString() === evt.target.value.toString()))) {
            this.setState({joueurPresent:true})
        } else {
            this.setState({joueurPresent:false})
        }
        if (evt.target.value !== "") {
            this.setState({nomVide:false})
        }
    }

  }

  export default JoueurList