import React from "react";
import Mode from "./mode";

class ModeSelector extends React.Component {

    constructor(props) {
        super(props)
        this.putXMode = this.putXMode.bind(this)
        this.state = {nbMode:0}
        this.state = {modes:this.putXMode(40)}
    }

    componentDidMount() {
        this.setState({nbMode:this.state.modes.length})
    }

    putXMode(x) {
        var element=[]
        var index;
        for (index = 0; index < x; index++) {
            element.push(<Mode num={index} key={index} jouer={() => {this.props.jouer()}}></Mode>);
        }
        return element
    }

    render () {
        return (
        <div className="w-full h-[95%] flex flex-col items-center justify-center">
            <div className="h-[25%] w-full flex items-center justify-center">
                <div className="w-[10%]">

                </div>
                <div className="w-[80%] text-3xl sm:text-5xl flex items-center justify-center text-white font-sans font-bold">
                    <h1>
                        Modes de jeu
                    </h1>
                </div>
                <div className="w-[10%]">

                </div>
            </div>
            <div className={"flex items-center "  + (this.state.nbMode <= 5 ? " sm:justify-center " : "")+"  h-full w-5/6 overflow-x-scroll pb-4 overflow-y-visible"}>
                {this.state.modes} 
            </div>
            <div className="h-[5%] bg"></div>
        </div>
        )
    }
}

export default ModeSelector