
export const inputNormal=" h-full hover:bg-opacity-30 focus:bg-opacity-30 bg-opacity-15 shadow bg-black border-1 border-opacity-10 rounded py-2 px-3 mr-4 text-white font-bold  "
export const inputError="h-full  shadow appearance-none border rounded w-5/6  py-2 px-3 mr-4 bg-red-50 border border-red-500 text-red-900 placeholder-red-700  dark:text-red-500 dark:placeholder-red-500 dark:border-red-500"

export const labelError="block mb-2 text-sm font-medium text-red-700 dark:text-red-500"

export const msgError=" mt-2 text-sm text-yellow-300 dark:text-red-500"

export const btnAddNormal="h-full  text-purple-900 border border-purple-900  hover:bg-purple-900 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center  dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 dark:hover:bg-blue-500"

export const btn="enabled:opacity-90 disabled:opacity-30 enabled:hover:opacity-100 bg-gradient-to-r from-purple-600 to-pink-900 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded-full "
export const btnInscription="opacity-60 hover:opacity-100 bg-gradient-to-r from-purple-600 to-pink-900 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded-full"
// export const addBtnSvg='<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-plus" viewBox="0 0 16 16" id="IconChangeColor"> <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" id="mainIconPathAttribute"></path> </svg>'
