
import React from "react";
import Accueil from "./accueil";
import Jeu from "./jeu";

class App extends React.Component {

  constructor(props) {
    super(props)

    this.jouer = this.jouer.bind(this)
    this.state =({joueurs:[], jeuEnCours:false})
  }

  jouer(joueurs) {
    console.log("noooooooooooooooooooo");
    this.setState({joueurs: joueurs})
    this.setState({jeuEnCours:true})
  }

  render() {
    return (
      <div>
        {!this.state.jeuEnCours && <Accueil/>}
      </div>
    );
  }
}


export default App;