import React from "react";
import ('./mode.css')
const front = "mode-front rounded-xl  min-w-40 flex-shrink-0 font-bold text-white bg-white bg-opacity-35"
const frontTitle = " flex items-center h-1/6 justify-center"
const frontDiv = "h-5/6 flex items-center justify-center "
class Mode extends React.Component {

    constructor(props) {
        super(props)
        this.clickHandle = this.clickHandle.bind(this)
        this.jouer = this.jouer.bind(this)
        this.log = this.log.bind(this)
        this.state = {num:1,
            clicked:false,
            clickDifficulte:false,
            props:props}
    }

    componentDidMount() {
        this.setState({num:this.state.props.num,
                        clicked:false,
                        clickDifficulte:false})
    }

    clickHandle() {
        if (!this.state.clickDifficulte) {
            console.log(this.state.clickDifficulte)
            this.setState({clicked:!this.state.clicked })
        }
    }

    log() {
        console.log("enter");
    }

    jouer(event) {
        event.stopPropagation()
        event.cancelBubble = true;
        if(event.stopPropagation) event.stopPropagation();
        this.props.jouer()
    }


    render() {
        return (
            <div className={"mode z-0 sm:mr-10 mr-3 mode h-4/6 w-1/6 min-w-60  rounded-xl shadow-2xl shadow-slate-900"} onClick={this.clickHandle}>
                <div className={this.state.clicked ? 'rounded-xl mode-inner flip' : 'z-0 rounded-xl mode-inner'}>
                    <div className={front}>
                        <div className={this.state.clicked ? "active " + frontTitle: frontTitle}>
                            <h3>Mode {this.state.num}</h3>
                        </div>
                        <hr></hr>
                        <div className={this.state.clicked ? "active " + frontDiv: frontDiv}>
                            <div className="overflow-scroll  h-5/6 w-5/6"> 
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur 
                                    Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                </p>
                            </div>
                        </div>

                    </div>
                    <div className="z-0 mode-back rounded-xl h-4/6 w-1/6 min-w-40 flex-shrink-0 bg-white bg-opacity-35 font-bold text-white">
                        <div className="flex items-center h-1/6 justify-center">
                            <h3>Difficulté</h3>
                        </div>
                        <hr></hr>
                        <div className="h-5/6 flex items-center justify-center ">
                            <div className="flex flex-col h-5/6 w-5/6 align-middle justify-center"> 
                                <div className="btn border rounded-xl pt-2 pb-2 mt-2 mb-2 active:bg-purple-800 active:text-white hover:bg-gradient-to-r from-purple-600 to-pink-900 hover:bg-opacity-35 hover:text-white">
                                    <button className="w-full h-full" onClick={this.jouer}>Facile</button>
                                </div>
                                <div onMouseEnter={this.log} className="z-10 border rounded-xl pt-2 pb-2 mt-2 mb-2 active:bg-purple-800 active:text-white hover:bg-gradient-to-r from-purple-600 to-pink-900 hover:bg-opacity-35 hover:text-white">
                                    <button className="w-full h-full" onClick={this.jouer}>Moyen</button>
                                </div>
                                <div className="border rounded-xl pt-2 pb-2 mt-2 mb-2 active:bg-purple-800 active:text-white hover:bg-gradient-to-r from-purple-600 to-pink-900 hover:bg-opacity-35 hover:text-white">
                                    <button className="w-full h-full" onClick={this.jouer}>Difficile</button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        )
    }
}
export default Mode