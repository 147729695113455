import Connexion from "./connexion/connexion";
import JoueurList from "./joueurList";

import React from "react";
import ModeSelector from "./modeSelector";
import Jeu from "./jeu";
class Accueil extends React.Component {

    constructor(props) {
        super(props)
        this.setConnected = this.setConnected.bind(this)
        this.state = {connected:false, 
            jeuEnCours:false, choixMode:false, joueurs:[]}
    }

    setConnected(bool) {
        this.setState({connected:true})
    }

    jouer() {
        this.setState({choixMode:false})
        this.setState({jeuEnCours:true})
    }

    choixMode(j) {
        console.log(j);
        this.setState({joueurs:this.state.joueurs.concat(j)}, () => this.setState({choixMode:true}))
        console.log("j1000");
    }
    

    render() {
        return ( 
            <div className="h-screen bg-gradient-to-r from-purple-600 to-pink-900">
                <div className="flex h-1/5 ">
                    <div className="w-[10%]">

                    </div>
                    <div className="w-[80%] mt-8 flex items-center justify-center">
                        <h1 className="text-white font-mainTitle text-4xl sm:text-6xl md:text-8xl mb-7 [text-shadow:_0_10px_0_rgb(0_0_0_/_10%)]">MISTER GRR</h1>
                        {this.state.connected}
                    </div>
                    <div className="w-[10%]">

                    </div>
                </div>    
                <div className="h-4/5 flex items-center flex-col justify-center bg-teal-lightest font-sans bg-gradient-to-r from-purple-600 to-pink-900 " id="d1">

                    <div className="h-[95%] w-full flex items-center justify-center">
                        {!this.state.connected && !this.state.choixMode && !this.state.jeuEnCours && <Connexion setConnected={(bool) => this.setConnected(bool)}></Connexion>}
                        {this.state.connected && !this.state.choixMode && !this.state.jeuEnCours && <JoueurList choixMode={(joueurs) => this.choixMode(joueurs)}></JoueurList>}
                        {this.state.connected && this.state.choixMode && !this.state.jeuEnCours && <ModeSelector jouer={() => this.jouer()}></ModeSelector>}
                        {this.state.jeuEnCours && this.state.connected && !this.state.choixMode && <Jeu joueurs={this.state.joueurs}/>}
                    </div>
                    <div>
                        {this.state.connected && <button onClick={() => {localStorage.removeItem('user'); this.setState({connected:false})}}>Déconnexion</button>}
                    </div>
                </div>
            </div>
        )
    }
}

export default Accueil
