import React from "react"
class Bouton extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            type:props.type.toString()
          };
    }
    render() {
        if (this.state.type==="add") {
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16" id="IconChangeColor"> <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" id="mainIconPathAttribute"></path> </svg>
            )
        } else if (this.state.type==="supprJoueur") {
            return (
                <svg width="24" height="24" strokeWidth="1" stroke="white" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M9.17218 14.8284L12.0006 12M14.829 9.17157L12.0006 12M12.0006 12L9.17218 9.17157M12.0006 12L14.829 14.8284" stroke="white" strokeLinecap="round" strokeLinejoin="round"/> <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="white" strokeLinecap="round" strokeLinejoin="round"/> </svg>   )
        }
    }
}

export default Bouton