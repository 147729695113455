
import React from "react";

var onlyonce = false
var waiting = false

class Jeu extends React.Component {


    constructor(props) {
        super(props)
        console.log(props);
        this.state = {
            questions: [],
            question:"Chargement...",
            questionsPassees: [],
            getting: false,
            chargement: true,
            joueurs: props.joueurs
        };
        console.log(props);
        this.getQuestion = this.getQuestion.bind(this)
        this.getQ = this.getQ.bind(this)

    }

    componentDidMount() {
        this.struct()
    }

    async struct() {
        if (onlyonce) return;
        onlyonce = true;
        this.getQuestion()
        console.log(this.state.joueurs);
    }



    async getQuestion() {
        console.log(this.state.joueurs);
        console.log("questions passées");
        console.log(this.state.questionsPassees);
        console.log("questions restantes");
        console.log(this.state.questions);
        console.log("question actuelle");
        console.log(this.state.question);
        if (this.state.questions && this.state.questions.length > 0) {
            this.setState({ question: this.state.questions.shift() })
        } else {
            this.setState({question: "Chargement...", chargement:true})
        }
        if (!waiting && this.state.questions.length < 5) {
            waiting = true
            const rep = await this.getQ(this)
            console.log(rep);
            this.setState({ questionsPassees: this.state.questionsPassees.concat(rep) })
            this.setState({ questions: this.state.questions.concat(rep) }, () => {
                if (this.state.chargement) {
                    this.setState({ question: this.state.questions.shift(), chargement: false })
                }
            })

            waiting = false
        }
    }

    async getQ() {
        return fetch('https://mister-grr-api.onrender.com/testgptapi', {
            method: "POST",
            mode: 'cors',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ "questionsPassees": this.state.questionsPassees, "joueurs": this.state.joueurs })
        })
            .then(response => response.json())
            .then(data => { return data })
            .catch(error => console.error(error))
    }

    render() {
        return (
            <div className="w-[90%] h-full flex items-center justify-center " onClick={t => this.getQuestion()}>
                <p Align="Center" className="text-3xl font-extrabold text-white">{this.state.question || "Chargement..."}</p>
            </div>
        )
    }
}

export default Jeu