
import React from "react";
import Joueur from "../joueur";
import * as Constants from "../constants"
import Bouton from '../boutons'
import {Link} from "react-router-dom";
import App from "../App";
class Connexion extends React.Component {

    constructor(props) {
        super(props)
        this.tryLog = this.tryLog.bind(this)
        this.tryRegister = this.tryRegister.bind(this)
        this.log = this.log.bind(this)
        this.register = this.register.bind(this)
        this.tryRegister = this.tryRegister.bind(this)
        this.updateEmailValue = this.updateEmailValue.bind(this)
        this.updateMdpValue = this.updateMdpValue.bind(this)
        this.checkKeyPressed = this.checkKeyPressed.bind(this)
        this.getSession = this.getSession.bind(this)
        this.state = {
            email:"",
            mdp:"",
            nonVide:false,
            emailDejaPris:false,
            idMdpIncorrect:false,
            parent:props.parent,
            app:props.app,
            isLoaded:false,
            logging:false
        }
        this.getSession()
    }

    getSession() {
        this.state.session = localStorage.getItem("user")
        if (this.state.session) {
            console.log((this.state.parent));
            console.log("s"+this.state.session);
            this.props.setConnected(true)
            console.log("Connected");
        } else {
            this.state = {isLoaded:true}
        }
        console.log(1);
    }
 
    async log() {
        console.log("try log");
        this.setState({logging:true})
        const rep = await this.tryLog()
        console.log("rep "+ rep);
        if (rep) {
            console.log("logged");
            localStorage.setItem('user', JSON.stringify(rep))
            this.getSession()
        } else {
            console.log("not logged");
        }
        this.setState({logging:false})
    }

    async tryLog() {
        console.log("try loggin");
        return fetch('https://mister-grr-api.onrender.com/login', {
            method: "POST",
            mode: 'cors',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ "email": this.state.email, "mdp": this.state.mdp })
        })  
        .then(response => {
            if (response.status === 403) {
                this.setState({idMdpIncorrect:true})
                return 
            } else if (response.status === 200) {
                return response.json()
            }
            throw new Error("Something went wrong")
        })
            .then(data => { return data })
            .catch(error => console.error(error))
    }

    register() {
        const reg = this.tryRegister()
        console.log((reg));
    }

    async tryRegister() {
        console.log("try register");
        return fetch('https://mister-grr-api.onrender.com/register', {
            method: "POST",
            mode: 'cors',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ "email": this.state.email, "mdp": this.state.mdp })
        })
            .then(response => {
                if (response.status === 401) {
                    this.setState({emailDejaPris:true})
                    return 
                } else if (response.status === 200) {
                    return response.json()
                }
                throw new Error("Something went wrong")
            })
            .then(data => { console.log(data) })
            .catch(error => console.log(error))
    }

    updateEmailValue(evt) {
        this.setState({
            email:evt.target.value,
            nonVide:evt.target.value!=="" && this.state.mdp!==""
        })
    }

    updateMdpValue(evt) {
        this.setState({
            mdp:evt.target.value,
            nonVide:evt.target.value!=="" && this.state.email!==""
        })
    }

    async checkKeyPressed(evt) {
        if (evt.code === "Enter") {
            const rep = await this.log()
            console.log(rep);
        }
    }

    render() {
        return (
            <div hidden={this.state.isLoaded ? false : true} className="bg-white bg-opacity-35 rounded p-6 m-4 w-4/5 sm:w-full lg:w-3/4 lg:max-w-lg shadow-2xl shadow-slate-900" onKeyDown={evt => this.checkKeyPressed(evt)}>
                <div className="flex items-center justify-center text-5xl font-medium text-white mb-8   ">
                    <h2>Connexion</h2>
                </div> 
                <div className="mb-4">
                    <div className="mb-5 ">
                        <label className=" text-white text-2xl mb-4 font-medium dark:text-white" htmlFor="identifiant">Email : </label>
                        <input className={Constants.inputNormal + " w-full"} id="identifiant" value={this.state.email} onChange={evt => this.updateEmailValue(evt)} />
                    </div>
                    <div className="mb-5">
                        <label className=" text-white text-2xl mb-4 font-medium dark:text-white" htmlFor="mdp">Mot de passe : </label>
                        <input type="password" className={Constants.inputNormal + " w-full"} id="mdp" onChange={evt => this.updateMdpValue(evt)}/>
                    </div>
                </div>
                <button className={Constants.btn} disabled={!this.state.nonVide} onClick={this.log}>{this.state.logging ? "..." : "Connexion" }</button>
                <button className={Constants.btnInscription + " ml-2"} onClick={this.register}>Inscription</button>
                {this.state.emailDejaPris && <p className={Constants.msgError}>Un compte avec cette adresse email existe déjà.</p> }
                {this.state.idMdpIncorrect && <p className={Constants.msgError}>L'email ou le mot de passe est incorrecte.</p>}
            </div>
        )
    }

}

export default Connexion