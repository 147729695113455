
import React from "react";
import Bouton from "./boutons";
class Joueur extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        parent: this.props.parent,
        nom:this.props.nom,
        id: this.props.id
      };
    }

    render() {
            return (
                <div className="flex mb-4 items-center border rounded-md p-4 " key={this.state.id}>
                    <p class="w-full text-white">{this.state.nom}</p>
                    <button className="mr-3" onClick={e => this.supprJoueur()}>
                        <Bouton type="supprJoueur"></Bouton>
                    </button>
                </div>
              )
    }
    
    supprJoueur() {
        this.state.parent.supprJoueur(this)
    }
    
  }

  export default Joueur